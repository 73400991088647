import { Container, Row, Col } from "react-bootstrap";

export const About = () => {


    return (
        <section className="banner" id="about">
            <Container>
                <Row className="align-items-center">
                    
                    <Col xs = {12} md = {6} xl = {12}>
                        <h1>{"About Me"}</h1>
                        <p> My name is Milton Nguy. I am interested in probability, machine learning, 
                            cloud computing technology, and innovation. I am interested in any roles relating to the computer science
                            field in pursuit of knowledge to gain personal growth and see if there are job roles 
                            that I might've overlooked but in fact was enjoyable!</p>
                    </Col>
                    <Col >
                        <div class="ratio ratio-16x9">
                            <iframe src="https://www.youtube.com/embed/ng1_dsp8iFs" title="Portfolio Video" allowfullscreen></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}