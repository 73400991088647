import { Container, Row, Col } from "react-bootstrap";

export const Contact = () => {


    return (
        <section className="banner" id="contact">
            <Container>
                <Row className="align-items-center">
                    <Col xl = {12}>
                        <h1>{"Contact"}</h1>
                        <p>Want to connect? Please reach out to me on linkedin and I will try to reply ASAP. I will be looking forward to any potential messages!</p>

                    </Col>
                </Row>
            </Container>

        </section>
    )
}